/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-card.mycard::v-deep {
  font-size: 0.95em;
}
.drone-card.mycard::v-deep > .ant-card-body {
  padding: 16px;
}
.drone-card.mycard::v-deep .mycard-details > li {
  margin-bottom: 4px;
}
.drone-lot-subcard.mycard::v-deep,
.drone-owner-subcard::v-deep {
  margin-top: 16px;
}
.drone-lot-subcard.mycard::v-deep .ant-card-body,
.drone-owner-subcard::v-deep .ant-card-body {
  padding: 6px 12px;
}
.mycard-with-details ul.mycard-details > li .label {
  min-width: 100px;
}
