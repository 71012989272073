/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.flight-time-select {
  width: 100px;
  margin-right: 16px;
}
.flight-time-input {
  display: inline-block;
}
.flight-time-input > .myinput-number {
  width: 75px;
}
