/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-action-card::v-deep {
  margin-top: 16px;
}
.drone-action-card::v-deep > .ant-card-body {
  padding: 16px;
}
.requested-change-label {
  color: red;
}
