/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-search-error {
  margin: 0 auto;
  max-width: 800px;
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 24px 24px;
}
.mobile .drone-search-error {
  font-size: 0.8em;
}
.drone-icon {
  display: block;
  margin: 0 auto;
  color: #f86b73;
  font-size: 10em;
}
.drone-icon::v-deep > .material-design-icon__svg {
  bottom: 0;
}
.message {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: 32px;
}
.message .title {
  font-size: 1.8em;
  color: #14837B;
  margin-bottom: 2px;
}
.message .description {
  font-size: 1.2em;
  color: #8c8c8c;
}
.message .detail {
  font-size: 1em;
  color: #8c8c8c;
  margin-top: 24px;
  text-align: left;
}
