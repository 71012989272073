/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.drone-status {
  white-space: nowrap;
}
.drone-status.drone-status-tag {
  display: inline-block;
  padding: 0 7px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 20px;
}
.drone-status .icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
}
.drone-status-tooltip-content {
  padding: 2px 4px;
}
.drone-status-new {
  color: #faad14;
}
.drone-status-new.drone-status-tag {
  color: #fff;
  background: #faad14;
}
.drone-status-new.drone-status-tag:hover {
  opacity: 0.85;
}
.drone-status-active {
  color: #52c41a;
}
.drone-status-active.drone-status-tag {
  color: #fff;
  background: #52c41a;
}
.drone-status-active.drone-status-tag:hover {
  opacity: 0.85;
}
.drone-status-decommission {
  color: #f5222d;
}
.drone-status-decommission.drone-status-tag {
  color: #fff;
  background: #f5222d;
}
.drone-status-decommission.drone-status-tag:hover {
  opacity: 0.85;
}
.drone-status-backup {
  color: #8c8c8c;
}
.drone-status-backup.drone-status-tag {
  color: #fff;
  background: #8c8c8c;
}
.drone-status-backup.drone-status-tag:hover {
  opacity: 0.85;
}
